import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Helmet from "react-helmet"
import styled, { keyframes } from "styled-components"

import Footer from "../components/Footer"
import seaPic from "../images/sea.jpg"
import fly from "../images/fly.png"

import "../css/typography.css"

const Nav = styled.div`
  background: url(${seaPic}) no-repeat center center;
  backgroundsize: cover;
`

const wavemotion = keyframes`
  0% {
  }
  100% {
    left: 120%;
  }
`
const surfer = keyframes`
  0% {
    top: 0;
    transform: rotate(-20deg) scaleX(-1) translateX(10px);
  }
  50% {
    top: 50px;
    transform: rotate(0deg) scaleX(-1);
  }
  100% {
    top: 0;
    transform: rotate(20deg) scaleX(-1) translateX(-10px);
  }
`

const Wave = styled.div`
  position: absolute;
  font-size: 300%;
  top: 24px;
  left: -120px;

  animation: ${wavemotion} 30s cubic-bezier(0.75, 0.25, 0.25, 0.25);

  @media (max-width: 480px) {
    top: 20px;
  }
`

const Surfer = styled.div`
  position: absolute;
  margin-top: -20px;
  margin-left: 20px;
  animation: ${surfer} 6s alternate cubic-bezier(0.75, 0.25, 0.25, 0.75)
    infinite;
  font-size: 30%;
`
const Kite = styled.div`
  width: 20px;
  height: 9px;
  background-color: transparent;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border: 2px solid yellow;
  border-bottom: 0;
  box-shadow: 2px -2px 0px 1px yellow;
  position: absolute;
  transform: translateX(-40px) translateY(-60px) rotate(-45deg);
`

export default class Template extends React.Component {
  static propTypes = {
    children: PropTypes.object,
    location: PropTypes.object,
  }

  componentDidMount() {
    // TODO make it work
    var links = document.getElementsByClassName("blog-post").links
    if (links) {
      for (var i = 0, linksLength = links.length; i < linksLength; i++) {
        if (links[i].hostname != window.location.hostname) {
          links[i].target = "_blank"
        }
      }
    }
  }

  render() {
    const { children } = this.props
    const isRoot = false
    // TODO: fix double loading of posts

    // const isRoot = location.pathname === "/"

    const PrimaryBlue = "#4169E1"
    const SecondaryBlue = "#add8e6"

    return (
      <div>
        <Helmet
          title={`Antoine Prelects`}
          meta={[
            { itemprop: "name", content: `Antoine Prelects` },
            {
              itemprop: "description",
              content: "Where he write on a World of unrelated concepts",
            },
            { itemprop: "image", content: fly },
            { name: "keywords", content: "homepage" },

            { property: "og:title", content: `Antoine Prelects` },
            { property: "og:type", content: "website" },
            { property: "og:url", content: "https://magnyficent.com" },
            { property: "og:image:url", content: fly },
            { property: "og:image:width", content: "750" },
            { property: "og:image:url", content: "750" },
            {
              property: "og:description",
              content: "Where he write on a World of unrelated concepts",
            },
            { property: "og:site_name", content: `Antoine Prelects` },

            { property: "twitter:card", content: "summary_large_image" },
            { property: "twitter:site", content: "helloiamantoine" },
            { property: "twitter:title", content: `Antoine Prelects` },
            {
              property: "twitter:description",
              content: "Where he write on a World of unrelated concepts",
            },
            { property: "twitter:creator", content: "helloiamantoine" },
            { property: "twitter:image:src", content: fly },
          ]}
        />
        <Nav>
          <div
            style={{
              textAlign: `center`,
              margin: `0 auto`,
              maxWidth: 960,
              padding: isRoot ? `0.45rem 1.0875rem` : `0.2rem 0.75rem`,
            }}
          >
            <h1
              style={{ margin: 0, fontSize: isRoot ? `2rem` : `1.5rem` }}
              className="main-title"
            >
              <Link
                to="/"
                className="post-title-link-dark"
                style={{
                  color: "white",
                  textDecoration: "none",
                  zIndex: `2 !important`,
                }}
              >
                <Wave>
                  🌊
                  <Surfer>
                    🏄
                    <Kite />
                  </Surfer>
                </Wave>
                Antoine Prelects
              </Link>
            </h1>
            <span
              style={{
                color: `white`,
                fontSize: `70%`,
                margin: `0`,
                fontFamily: "Helvetica Neue",
              }}
            >
              {`He also discants. But he certainly won't 'blog'`}
            </span>
          </div>
        </Nav>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: `1.45rem`,
            backgroundColor: `white`,
            minHeight: `100vh`,
          }}
        >
          {children}
        </div>
        <Footer />
      </div>
    )
  }
}
