import React, { Component } from "react"
import Layout from "../components/layout"

export default class Thanks extends Component {
  render() {
    return (
      <Layout>
        <p className="text-center">Great message.</p>
        <p className="text-center">{"I'll get back to you ASAP! 😘"}</p>
      </Layout>
    )
  }
}
