import React, { Component } from "react"
import styled from "styled-components"

import { Link } from "gatsby"

import { FaTelegramPlane, FaTwitter, FaEnvelope } from "react-icons/fa"

import seaPic from "../images/sea.jpg"

const footerHeight = "60px"
const FooterContainer = styled.div`
  width: 100%;
  height: ${footerHeight};
  background-color: #4169e1;
  position: fixed;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: url(${seaPic}) no-repeat top center;
  background-size: cover;

  &.active {
    bottom: 0px;
  }
  & a,
  a:hover,
  a:visited,
  a:active {
    transition: all 0.5s ease-in-out;
    text-decoration: none;
    color: white;
  }
  & a:hover,
  a:hover svg {
    transform: scale(0.9);
  }
`

// const FlexColumn = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   & span {
//     height: 15px;
//     font-size: 60%;
//   }
// `

export default class Footer extends Component {
  constructor() {
    super()

    this.state = {
      oldScroll: 0,
      styles: { bottom: "-100px" },
    }

    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll() {
    let doc = document.documentElement
    let top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    let test = this.state.oldScroll > top

    // console.log("oldScroll " + this.state.oldScroll + " scrollY " + top + " test " + test)
    if (top == 0 && this.state.oldScroll != 0) {
      // for staying at top
      this.setState({
        oldScroll: top - 1,
        styles: { bottom: `0px` },
      })
      // } if (top == 0 ) {
      //   // for hiding at top
      //   this.setState({
      //     oldScroll: top,
      //     styles: {bottom: `-${footerHeight}`}
      //   })
    } else if (test) {
      // console.log(`scrolling up`)
      this.setState({
        oldScroll: top,
        styles: { bottom: "0px" },
      })
    } else {
      // console.log(`scrolling down`)
      this.setState({
        oldScroll: top,
        styles: { bottom: `-${footerHeight}` },
      })
    }
  }

  render() {
    return (
      <FooterContainer className="footer" style={this.state.styles}>
        {/*<a href="CV">
          <FlexColumn>
            <Briefcase />
            <span>CV</span>
          </FlexColumn>
        </a>*/}

        <a
          href="https://t.me/iamantoine"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTelegramPlane />
        </a>

        <Link to="/Contact">
          <FaEnvelope />
        </Link>

        <a
          href="https://twitter.com/helloiamantoine"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </a>
      </FooterContainer>
    )
  }
}
